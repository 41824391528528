import React, {useState} from 'react';
import Tabs, {Tab} from "@hig/tabs";
import styled from 'styled-components';
import RichText from '@hig/rich-text';
import "@hig/fonts/build/ArtifaktElement.css";
import ThemeContext from '@hig/theme-context';
import TextLink from '@hig/text-link';
import Surface from '@hig/surface';
import "@hig/fonts/build/ArtifaktElement.css";
import Typography from '@hig/typography';
import Timestamp from '@hig/timestamp';
import HIGDarkBlueTheme from '@hig/theme-data/build/esm/darkBlueMediumDensityTheme';
import lightGrayMediumDensityTheme from '@hig/theme-data/build/esm/lightGrayMediumDensityTheme';
import {resolvedRoles} from '@hig/theme-data/build/json/lightGrayMediumDensityTheme/theme.json';
import Button from "@hig/button";

const PageContentDiv = styled.span`
    padding: ${resolvedRoles['basics.spacings.highExtraLarge']};
    background-color: ${resolvedRoles['basics.colors.white']};
`;

const IncidentDetailsDiv = styled.div`
    padding: ${resolvedRoles['basics.spacings.highExtraLarge']};
    margin-top: ${resolvedRoles['basics.spacings.highExtraLarge']};
`;

const IncidentUpdatesDiv = styled.div`
    padding: ${resolvedRoles['basics.spacings.highExtraLarge']};
    margin-top: ${resolvedRoles['basics.spacings.highExtraLarge']};
    background-color: ${resolvedRoles['basics.colors.charcoal100']};
    height: 300px;
    overflow-y: auto;
    
`;

function setIncidentDetailsDivColor(status) {
    if (status === "Open") {
        return resolvedRoles['basics.colors.red200'];
    } else if (status === "Resolved") {
        return resolvedRoles['basics.colors.yellowOrange200'];

    } else if (status === "Closed") {
        return resolvedRoles['basics.colors.green200'];
    }
}

class Incident extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };

    }

    componentDidMount() {
        // console.log("MOUNTING");
        const id = this.props.match.params.id;
        fetch('/' + id).then((result) => result.json())
            .then((response) => {
                // console.log(response.id);
                this.setState(response);
                this.setState({loaded: true});
            });
    }

    render() {
        // console.log(this.state['loaded']);
        if (!this.state['loaded']) {
            // console.log("HERE");
            // console.log(this.state);
            return (
                <ThemeContext.Provider value={lightGrayMediumDensityTheme}>
                </ThemeContext.Provider>)
        } else {
            console.log("IN HERE");
            // console.log(this.state);
            return (
                <ThemeContext.Provider value={lightGrayMediumDensityTheme}>
                    <PageContentDiv>
                        <Tabs align="center">
                            <Tab label="Overview">
                                <IncidentDetailsDiv
                                    style={{backgroundColor: setIncidentDetailsDivColor(this.state['status'])}}>
                                    <RichText>
                                        <strong>INCIDENT ID</strong>: <TextLink
                                        link={this.state['incident_record']}
                                        target="_blank">COE-INC{this.state['id']}</TextLink>
                                    </RichText>
                                    <RichText>
                                        <strong>SERVICE</strong>: <TextLink
                                        link={this.state['service_url']}
                                        target="_blank">{this.state['service']}</TextLink>
                                    </RichText>
                                    <RichText>
                                        <TextLink
                                            link="https://wiki.autodesk.com/x/NbDPDQ"
                                            target="_blank"><strong>SEVERITY</strong></TextLink>: {this.state['severity']}
                                    </RichText>
                                    <RichText>
                                        <strong>STATUS</strong>: {this.state['status']}
                                    </RichText>
                                    <RichText>
                                        <strong>START TIME</strong>: {this.state['start_time']}
                                    </RichText>
                                    <RichText>
                                        <strong>END TIME</strong>: {this.state['end_time']}
                                    </RichText>
                                </IncidentDetailsDiv>
                                <IncidentUpdatesDiv>
                                    <RichText>
                                        <strong>INCIDENT UPDATES:</strong>
                                    </RichText>
                                    <RichText>
                                        skldjfksdfjklsdfjksdlfjsdklfjskdlfjskdlfjsdklf
                                        fdksjflksjflksfjklsdfjklsdfjksdf
                                        sdfkhsdfklskflshflkdhflksdf
                                        dlfhslfhlskfhlskfhskldfhlskdfhklsdhflksdfhsd
                                        fkshflksdhflksdfh
                                    </RichText>
                                    <RichText>
                                        skldjfksdfjklsdfjksdlfjsdklfjskdlfjskdlfjsdklf
                                        fdksjflksjflksfjklsdfjklsdfjksdf
                                        sdfkhsdfklskflshflkdhflksdf
                                        dlfhslfhlskfhlskfhskldfhlskdfhklsdhflksdfhsd
                                        fkshflksdhflksdfh
                                    </RichText>
                                    <RichText>
                                        skldjfksdfjklsdfjksdlfjsdklfjskdlfjskdlfjsdklf
                                        fdksjflksjflksfjklsdfjklsdfjksdf
                                        sdfkhsdfklskflshflkdhflksdf
                                        dlfhslfhlskfhlskfhskldfhlskdfhklsdhflksdfhsd
                                        fkshflksdhflksdfh
                                    </RichText>
                                    <RichText>
                                        skldjfksdfjklsdfjksdlfjsdklfjskdlfjskdlfjsdklf
                                        fdksjflksjflksfjklsdfjklsdfjksdf
                                        sdfkhsdfklskflshflkdhflksdf
                                        dlfhslfhlskfhlskfhskldfhlskdfhklsdhflksdfhsd
                                        fkshflksdhflksdfh
                                    </RichText>
                                    <RichText>
                                        skldjfksdfjklsdfjksdlfjsdklfjskdlfjskdlfjsdklf
                                        fdksjflksjflksfjklsdfjklsdfjksdf
                                        sdfkhsdfklskflshflkdhflksdf
                                        dlfhslfhlskfhlskfhskldfhlskdfhklsdhflksdfhsd
                                        fkshflksdhflksdfh
                                    </RichText>
                                    <RichText>
                                        skldjfksdfjklsdfjksdlfjsdklfjskdlfjskdlfjsdklf
                                        fdksjflksjflksfjklsdfjklsdfjksdf
                                        sdfkhsdfklskflshflkdhflksdf
                                        dlfhslfhlskfhlskfhskldfhlskdfhklsdhflksdfhsd
                                        fkshflksdhflksdfh
                                    </RichText>
                                    <RichText
                                        style={{backgroundColor: setIncidentDetailsDivColor(this.state['status'])}}>
                                        skldjfksdfjklsdfjksdlfjsdklfjskdlfjskdlfjsdklf
                                        fdksjflksjflksfjklsdfjklsdfjksdf
                                        sdfkhsdfklskflshflkdhflksdf
                                        dlfhslfhlskfhlskfhskldfhlskdfhklsdhflksdfhsd
                                        fkshflksdhflksdfh
                                    </RichText>
                                </IncidentUpdatesDiv>
                                <Button title="Add Update" style={{marginTop: "10px"}}/>
                            </Tab>
                            <Tab label="Timeline">
                                <Surface
                                    horizontalPadding="m"
                                    verticalPadding="m"
                                    shadow="low"
                                    borderRadius="l"
                                    >
                                    <Typography>Hello</Typography>
                                    <Timestamp timestamp="2020-04-01T20:15:00.000Z"/>
                                </Surface>
                            </Tab>
                        </Tabs>
                    </PageContentDiv>
                </ThemeContext.Provider>
            )
        }
    };
}

export default Incident;