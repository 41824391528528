import React from "react";
import { NavLink } from "react-router-dom";
import ThemeContext from '@hig/theme-context';
import { resolvedRoles } from '@hig/theme-data/build/json/darkBlueMediumDensityTheme/theme.json';
import styled from 'styled-components';
import TopNav from '@hig/top-nav';
import '@hig/top-nav/build/index.css';
import logo from './images/airbot.svg'
import TextLink from '@hig/text-link';
import "@hig/text-link/build/index.css"


const TopNavContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  /* affects Interactions wrapper */
  & .hig__top-nav__interactions {
    padding: 0 ${resolvedRoles['basics.spacings.highMedium']};
  }
`;

const TitleContainer = styled.span`
  margin-right: ${resolvedRoles['basics.spacings.highExtraLarge']};
`;

const LinkWrapper = styled.span`
  margin-right: ${resolvedRoles['basics.spacings.highExtraLarge']};
  & a {
    text-decoration: none;
  }
  /* affects active link */
  & .topnav-link--active .hig__text-link {
    font-weight: bold;
    border-bottom: 1px solid ${resolvedRoles['basics.colors.darkBlue800']};
    padding-bottom: 2px;
  }
`;


function Header() {
  return (
      <TopNavContainer>
      <TopNav
        logo={<div>
            <img src={logo} style={{width:'40px', height:'40px', margin:'5px'}}></img>
            <TitleContainer>
              <strong>AIRBOT DASHBOARD</strong>
            </TitleContainer>
            <LinkWrapper>
              <NavLink activeClassName="topnav-link--active" exact to="/">
                <TextLink> HOME</TextLink>
              </NavLink>
            </LinkWrapper>
            </div>
          }
      />
      </TopNavContainer>
  );
}

export default Header;