import React, {Component}  from 'react';
import { resolvedRoles } from '@hig/theme-data/build/json/darkBlueMediumDensityTheme/theme.json';
import styled from 'styled-components';
import Timestamp from '@hig/timestamp';
import {Link} from "react-router-dom";

const IncidentDiv = styled.div`
  box-sizing: border-box;
  flex: 0 0 calc(25% - ${resolvedRoles['basics.spacings.mediumSmall']});
  overflow: hidden;
  background-color: ${resolvedRoles['basics.colors.white']};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: ${resolvedRoles['basics.spacings.mediumSmall']};
  margin-right: ${resolvedRoles['basics.spacings.mediumSmall']};
  border-radius: 2px;
  border-width: 3px;
  border-style: solid;
  border-color: ${props => setColorFromSeverity(props.severity)};
`;

const IncidentContent = styled.div`

`;

function setColorFromSeverity(sev){
    if(sev >= 3){
        return (resolvedRoles['basics.colors.green500']);
    }
    else if (sev == 1 ||  sev == 2){
        return (resolvedRoles['basics.colors.red600']);
    }
    else if (sev == 0){
        return (resolvedRoles['basics.colors.red700']);
    }
}

class IncidentCard extends Component{
    constructor(props){
        super(props)
    }

    render() {
        return(
            <>
                <IncidentDiv severity={this.props.severity}>
                   <a style={{fontFamily:'ArtifaktElement ,sans-serif',fontWeight:'bold'}} href={"/incidents/COE-INC"+this.props.incident_id}>COE-INC{this.props.incident_id}</a>
                        <p><strong>Service:</strong> {this.props.service}<br />
                        <strong>Severity:</strong> {this.props.severity}<br />
                        <strong>{this.props.status+' for:'}</strong>{this.props.duration}</p>
                </IncidentDiv>
            </>
        )
    }
};

export default IncidentCard;
/*<Link to="/incidents/"+this.props.incident_id>COE-INC{this.props.incident_id}</Link>*/