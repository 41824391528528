import React, {Component}  from 'react';
import { resolvedRoles } from '@hig/theme-data/build/json/darkBlueMediumDensityTheme/theme.json';
import styled from 'styled-components';

const StatusCardWrapper = styled.div`
  background-color: ${resolvedRoles['colorScheme.surfaceLevel15Color']};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin-bottom: ${resolvedRoles['basics.spacings.mediumSmall']};
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: ${resolvedRoles['basics.colors.charcoal400']};
`;

const StatusCardContent = styled.div`
  background-color: ${resolvedRoles['basics.colors.surfaceLightGrayLevel100']};
  padding: ${resolvedRoles['density.spacings.medium']} 0 0;
  border : 2px;
  max-height: 512px;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  &::after {
    content: '';
    width: 100%;
    height: 1px;
  }
`;

const CardHeaderWrapper = styled.div`
  background-color: ${props => setColorFromStatus(props.status)};
  /* eliminate awkward border radius on original progress bar */
  & .hig__progress-bar {
    border-radius: 0;
  }
`;

function setColorFromStatus(status){
    if(status == 'Open'){
        return (resolvedRoles['basics.colors.salmon600']);
    }
    else if (status == 'Resolved'){
        return (resolvedRoles['basics.colors.yellowOrange500']);
    }
    else if (status == 'Closed'){
        return (resolvedRoles['basics.colors.green500']);
    }
}

const CardHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${resolvedRoles['basics.spacings.mediumSmall']};
  font-size: ${resolvedRoles['basics.fontSizes.highLarge']};
  line-height: ${resolvedRoles['basics.lineHeights.regular']};
`;

const CardHeaderTitleName = styled.div`
  font-weight: ${resolvedRoles['basics.fontWeights.medium']}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${resolvedRoles['basics.fontSizes.highLarge']};
  color: ${resolvedRoles['basics.colors.white']};
`;

const CardHeaderTitlePctg = styled.div`
  /* minimum width for the status percentage to fit */
  flex-basis: 40px;
  text-align: right;
  font-weight: ${resolvedRoles['basics.fontWeights.medium']}
  color: ${resolvedRoles['basics.colors.white']};
  font-size: ${resolvedRoles['basics.fontSizes.highLarge']};
`;


class StatusCard extends Component {
    constructor(props){
        super(props);
    }
  render() {
    return (
      <StatusCardWrapper>
          <CardHeaderWrapper status = {this.props.status}>
              <CardHeaderTitle>
                  <CardHeaderTitleName>{this.props.status}</CardHeaderTitleName>
                  <CardHeaderTitlePctg>{this.props.numIncidents}</CardHeaderTitlePctg>
              </CardHeaderTitle>
          </CardHeaderWrapper>
        <StatusCardContent>{this.props.children}</StatusCardContent>
      </StatusCardWrapper>
    );
  }
};

export default StatusCard;