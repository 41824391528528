import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Home from "./Home";
import Incident from "./Incident"
import styled from 'styled-components';
import { resolvedRoles } from '@hig/theme-data/build/json/darkBlueMediumDensityTheme/theme.json';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 70px;
  background-color: ${resolvedRoles['basics.colors.charcoal100']}
`;


class Contents extends Component {
    constructor(props) {
        super(props);
    }

    // static getDerivedStateFromProps(props, state){
    //     if (props.data && state.incidents!= props.data){
    //         return{
    //             incidents : props.data
    //         }
    //     }
    //     return null
    // }

    render() {
            return (
                <ContentWrapper>
                    <Switch>
                        <Route exact path="/" render={() => <Home/>}/>
                        <Route exact path="/incidents/:id" render={(props) => <Incident {...props}/>}/>}/>
                    </Switch>
                </ContentWrapper>
            );
        }
};

export default Contents