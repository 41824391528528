import React, {Component}  from 'react';
import ThemeContext from '@hig/theme-context';
import HIGDarkBlueTheme from '@hig/theme-data/build/esm/darkBlueMediumDensityTheme';
import { resolvedRoles } from '@hig/theme-data/build/json/darkBlueMediumDensityTheme/theme.json';
import styled, {css} from 'styled-components';
import HomeOptions from './HomeOptions';
import IncidentCard from './IncidentCard';
import StatusCard from './StatusCard';

const OverviewDiv = styled.span`
    padding-left: 15px;
    font-weight: bold;
    color: ${resolvedRoles['basics.colors.surfaceDarkGrayLevel200']};
`;

const flexItem = css`
    flex: 1;
`;

const FlexItem = styled.div`
    ${flexItem}
`;

const Content = styled(FlexItem)`
  position: relative;
  margin-left: ${resolvedRoles['basics.spacings.mediumSmall']};
  margin-top: ${resolvedRoles['basics.spacings.mediumSmall']};
`;

let groupIncidentsByStatus = function (arrIncidents, key) {
    return arrIncidents.reduce(function (group, incident) {
            (group[incident[key]] = group[incident[key]] || []).push(incident);
            return group;
        }, {});
};

class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            sortBy: 'severity',
            groupByStatus: true,
            incidents: [],
            isLoadingData: false,
            isDataAvailable: false,
            error: null
        };
        this.getIncidentBySeverity = this.getIncidentBySeverity.bind(this);
        this.getIncidentByService = this.getIncidentByService.bind(this);
        this.handleGroupByChange = this.handleGroupByChange.bind(this);
        this.renderIncidentCard = this.renderIncidentCard.bind(this);
    }

    componentDidMount() {
        console.log('componentDidMount');
        if(!this.state.isDataAvailable && !this.state.isLoadingData){
            this.setState({isLoadingData : true});
            this.getIncidentBySeverity()
        }
        return null
    }

    getIncidentBySeverity(){
        const data = fetch("/getIncidentsBySeverity").then(res => res.json())
              .then(
                (result) => {
                  this.setState({
                    incidents: result,
                    isLoadingData: false,
                    isDataAvailable: true,
                  })
                },
                (error) => {
                  this.setState({
                    error:error,
                    isLoadingData: false,
                    isDataAvailable: false,
                  });
                }
              );
    }

    getIncidentByService(){
        const data = fetch("/getIncidentsByService").then(res => res.json())
          .then(
            (result) => {
                this.setState({
                    incidents: result,
                    isLoadingData: false,
                    isDataAvailable: true,
                  });
            },
            (error) => {
             this.setState({
                    error:error,
                    isLoadingData: false,
                    isDataAvailable: false,
             });
            }
          );
    }

    handleGroupByChange(event){
        switch (event){
            case 'Status':
                console.log('inside status');
                this.setState({groupByStatus:true});
                break;

            case 'None':
                this.setState({groupByStatus:false});
                break;

            default:
                console.log("Incorrect value")
        }
    }

    renderIncidentCard(incidents){
        return( incidents.map((item) =>
            <IncidentCard {...item}/>)
        )
    }

    showIncidents(){
        const items = this.state.incidents;
        let homeContent;

        if (items.length === 0){
            homeContent = <OverviewDiv>No open or resolved incidents</OverviewDiv>
            return (homeContent);
        }
        else {
             if (this.state.groupByStatus){
                const data = groupIncidentsByStatus(items,'status');
                if(Object.keys(data).length >= 1) {
                    let num_incidents;
                    return (
                        <Content>
                            {Object.keys(data).map((group) =>
                                <div key={group}>
                                    <StatusCard status={group} numIncidents={data[group].length}>
                                        {this.renderIncidentCard(data[group])}
                                    </StatusCard>
                                </div>
                            )}
                        </Content>
                    )
                }
             }
             else if (!this.state.groupByStatus){
                 return (
                      <Content>
                        {this.renderIncidentCard(items)}
                      </Content>
                 )
             }
        }
    }

    render() {
        console.log("Inside Render");
        let props = {
            sortedBySeverity : this.getIncidentBySeverity,
            sortedByService: this.getIncidentByService,
            groupByChanged : this.handleGroupByChange
        };

        return(
                <>
                    <ThemeContext.Provider value={HIGDarkBlueTheme}>
                    <OverviewDiv>Overall Incidents</OverviewDiv>
                    <HomeOptions {...props}/>
                    {this.showIncidents()}
                    </ThemeContext.Provider>
                </>
        );
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     console.log('Inside Component Did mount')
    //
    // }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // Render if state changes
        console.log("shouldComponentUpdate");

        let prevState = '';
        const prev = this.state.incidents.map(incident=>{prevState+=((Object.values(incident)).join(''))});

        let next = '';
        const result = nextState.incidents.map(incident=>{next+=((Object.values(incident)).join(''))});

        return (next !== prevState || (nextState.groupByStatus != this.state.groupByStatus));
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error:errorInfo.componentStack});
        console.log('Caught error in Home.js.\n Got: '+ errorInfo.componentStack)
    }

    componentWillUnmount(){
        this.state.isLoadingData = false;
        this.state.isDataAvailable = false;
    }
}

export default Home;

