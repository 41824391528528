import React, {useEffect, useState, Component}  from 'react';
import ThemeContext from '@hig/theme-context';
import HIGDarkBlueTheme from '@hig/theme-data/build/esm/darkBlueMediumDensityTheme';
import { resolvedRoles } from '@hig/theme-data/build/json/darkBlueMediumDensityTheme/theme.json';
import Label from '@hig/label'
import Dropdown from '@hig/dropdown'
import styled from 'styled-components';


const ListIncidentsWrapper = styled.div`
  min-width: 500px;
  padding: ${resolvedRoles['density.spacings.medium']};
  align-self: flex-start;
  display: flex;
 `;

const DropdownWrapper = styled.div`
  flex-grow: 1;
`;

const DividerWrapper = styled.span`
    color: ${resolvedRoles['basics.colors.charcoal700']};
    margin-left: ${resolvedRoles['basics.spacings.highLarge']};
    margin-right: ${resolvedRoles['basics.spacings.highLarge']};
    margin-top: ${resolvedRoles['basics.spacings.highExtraExtraSmall']};
    margin-bottom: ${resolvedRoles['basics.spacings.highExtraExtraSmall']};
    border-left: 1px solid ${resolvedRoles['basics.colors.slate300']};
    align-self: stretch;
`;
class HomeOptions extends Component{

    constructor(props){
        super(props);

        this.handleSortByChange = this.handleSortByChange.bind(this);
        this.handleGroupByChange = this.handleGroupByChange.bind(this);
    }

    handleSortByChange(event){
        switch (event) {
            case 'Severity':
                this.props.sortedBySeverity();
                break;

            case 'Service Name':
                this.props.sortedByService();
                break;

            default:
                this.props.sortedBySeverity();
                break;
        }
    }

    handleGroupByChange(event){
        this.props.groupByChanged(event)
    }

    render() {
        return (
            <>
                <ThemeContext.Provider value={HIGDarkBlueTheme}>
                    <ListIncidentsWrapper>
                        <DropdownWrapper>
                            <Label disabled={false}
                                   style={{color: resolvedRoles['basics.colors.surfaceDarkGrayLevel200']}}>Sort
                                By </Label>
                            <Dropdown
                                variant="box"
                                placeholder="Select"
                                disabled={false}
                                error={false}
                                defaultValue={'Severity'}
                                required=""
                                multiple={false}
                                options={['Severity', 'Service Name']}
                                onChange= {this.handleSortByChange}
                            />
                        </DropdownWrapper>
                        <DividerWrapper></DividerWrapper>
                        <DropdownWrapper>
                            <Label disabled={false}
                                   style={{color: resolvedRoles['basics.colors.surfaceDarkGrayLevel200']}}>Group
                                By</Label>
                            <Dropdown
                                variant="box"
                                placeholder="Select"
                                disabled={false}
                                error={false}
                                defaultValue={'Status'}
                                required=""
                                multiple={false}
                                options={['Status', 'None']}
                                onChange={this.handleGroupByChange}
                            />
                        </DropdownWrapper>
                    </ListIncidentsWrapper>
                </ThemeContext.Provider>
            </>
        );
    }

    componentDidCatch(error, errorInfo) {
      console.log('Caught error in HomeOptions.js.\n Got: '+ errorInfo.componentStack)
    }
}

export default HomeOptions;