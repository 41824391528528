import React, { Fragment, Component } from 'react';
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Header from "./Header";
import Contents from "./Contents"
import 'bootstrap/dist/css/bootstrap.min.css';


class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const response = fetch("/").then(console.log("yuhu"));
  }

  render(){
    return (
      <>
          <Header />
          <Contents/>
     </>
    );
  }

  componentDidCatch(error, errorInfo) {
      console.log('Caught error in App.js.\n Got: '+ error)
  }
}

export default App;
